<template>
  <div>
    <div v-if="isLoading">
      <div v-if="!isMobile()">
        <div class="d-flex justify-center">
          <v-skeleton-loader
              class="mx-auto round-16 pa-1"
              width="1200"
              height="200"
              type="card"
            ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-5">
          <v-skeleton-loader
              class="mx-auto round-16 pa-1"
              width="1200"
              type="card"
            ></v-skeleton-loader>
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-center mt-5 pt-5">
          <v-skeleton-loader
            class="mx-auto mr-2 round-16"
            width="200"
            height="150"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-2 round-16"
            width="200"
            height="150"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-3">
          <v-skeleton-loader
            class="mx-auto mr-2 round-16"
            width="200"
            height="150"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-2 round-16"
            width="200"
            height="150"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-3">
          <v-skeleton-loader
            class="mx-auto mr-2 round-16"
            width="200"
            height="150"
            type="card"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto ml-2 round-16"
            width="200"
            height="150"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center mt-10 pt-10">
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="1200"
            height="200"
            type="card"
          ></v-skeleton-loader>
        </div>
        <div class="d-flex justify-center pt-5">
          <v-skeleton-loader
            class="mx-auto pa-1 round-16"
            width="1200"
            height="200"
            type="card"
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- DESKTOP -->
      <div class="headline black--text font-weight-bold d-flex" v-if="!isMobile()">
        <div>
          <p class="mb-1 mt-0">Campaign Report</p>
          <p class="mb-2 subtitle-2">{{ itemData[0].name}}</p>
        </div>
        <p class="ml-auto mt-4 subtitle-2">{{ itemData[0].date }}</p>
        <!-- <v-btn color="primary" class="text-capitalize ml-auto mt-2" @click.prevent="downloadFile(itemData[0].invoice)"><i class="fas fa-download mr-2"></i> Download Excel</v-btn> -->
      </div>
      <!-- MOBILE -->
      <div class="black--text pt-0" v-else>
        <!-- Report Campaign -->
        <p class="mb-0 text-20 font-weight-bold text-center">{{ itemData[0].name}}</p>
        <p class="mb-5 text-10 text-center">{{ itemData[0].date }}</p>
        <!-- <v-btn block color="primary" class="text-capitalize ml-auto mt-2" @click.prevent="downloadFile(itemData[0].invoice)"><i class="fas fa-download mr-2"></i> Download Excel</v-btn> -->
      </div>

      <!-- DESKTOP -->
      <div v-if="!isMobile()">
        <v-row class="mt-0">
          <div class="col-md-12 col-sm-12 col-lg-12">
            <v-card class="overflow-hidden elevation-1 round-16">
              <!-- <v-card-title class="subtitle-1 font-weight-bold">Total Influencers {{ itemData.length }}</v-card-title> -->
              <v-card-actions class="d-flex justify-center subtitle-2">
                <div class="row">
                  <div class="col-md-2 col-sm-4 col-6 border-right">
                    <p>
                      <v-btn
                        class="mx-2 ml-0"
                        fab
                        small
                        color="orange"
                      >
                        <i class="far fa-user white--text text-16"></i>
                      </v-btn>
                    </p>
                    <p class="text-14 mt-4">People Reached</p>
                    <h1>{{ reach | nFormatter}}</h1>
                    <p class="text-14 mt-4 mb-0">
                      <span class="font-weight-bold success--text">IDR {{ limitNumber(cpr) | nFormatter }}</span>
                      CPR
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <svg v-bind="attrs"
                            v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                        </template>
                        <span>Cost Per Reach</span>
                      </v-tooltip>
                    </p>
                  </div>
                  <div class="col-md-2 col-sm-4  col-6 border-right">
                    <p>
                      <v-btn
                        class="mx-2 ml-0"
                        fab
                        small
                        color="success"
                      >
                        <i class="fas fa-history white--text text-16"></i>
                      </v-btn>
                    </p>
                    <p class="text-14 mt-4">Impressions</p>
                    <h1>{{ impressions | nFormatter}}</h1>
                    <p class="text-14 mt-4 mb-0">
                      <span class="font-weight-bold success--text">IDR {{ limitNumber(cpi) | nFormatter }}</span>
                      CPI
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <svg v-bind="attrs"
                            v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                        </template>
                        <span>Cost Per Impressions</span>
                      </v-tooltip>
                    </p>
                  </div>
                  <div class="col-md-2 col-sm-4  col-6 border-right">
                    <p>
                      <v-btn
                        class="mx-2 ml-0"
                        fab
                        small
                        color="info"
                      >
                        <i class="far fa-eye white--text text-16"></i>
                      </v-btn>
                    </p>
                    <p class="text-14 mt-4">Views</p>
                    <h1>{{ views | nFormatter}}</h1>
                    <p class="text-14 mt-4 mb-0">
                      <span class="font-weight-bold success--text">IDR {{ limitNumber(cpv) | nFormatter }}</span>
                      CPV
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <svg v-bind="attrs"
                            v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                        </template>
                        <span>Cost Per Views</span>
                      </v-tooltip>
                    </p>
                  </div>
                  <div class="col-md-2 col-sm-4  col-6 border-right">
                    <p>
                      <v-btn
                        class="mx-2 ml-0"
                        fab
                        small
                        color="secondary"
                      >
                        <i class="far fa-thumbs-up white--text text-16"></i>
                      </v-btn>
                    </p>
                    <p class="text-14 mt-4">Likes</p>
                    <h1>{{ likes | nFormatter}}</h1>
                    <p class="text-14 mt-4 mb-0">
                      <span class="font-weight-bold success--text">{{ limitNumber(comments) | nFormatter }}</span>
                      Comments
                    </p>
                  </div>
                  <!-- <div class="col-md-2 col-sm-4  col-6 border-right">
                    <p>
                      <v-btn
                        class="mx-2 ml-0"
                        fab
                        small
                        color="warning"
                        @click="changeSummary('cpr')"
                      >
                        <svg class="white--text text-22" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M20 12a2 2 0 0 0-.703.133l-2.398-1.963c.059-.214.101-.436.101-.67C17 8.114 15.886 7 14.5 7S12 8.114 12 9.5c0 .396.1.765.262 1.097l-2.909 3.438A2.06 2.06 0 0 0 9 14c-.179 0-.348.03-.512.074l-2.563-2.563C5.97 11.348 6 11.179 6 11c0-1.108-.892-2-2-2s-2 .892-2 2s.892 2 2 2c.179 0 .348-.03.512-.074l2.563 2.563A1.906 1.906 0 0 0 7 16c0 1.108.892 2 2 2s2-.892 2-2c0-.237-.048-.46-.123-.671l2.913-3.442c.227.066.462.113.71.113a2.48 2.48 0 0 0 1.133-.281l2.399 1.963A2.077 2.077 0 0 0 18 14c0 1.108.892 2 2 2s2-.892 2-2s-.892-2-2-2z"/></svg>
                      </v-btn>
                    </p>
                    <p v-if="cprSum" class="text-14 mt-4">
                      CPR
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <svg v-bind="attrs"
                            v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                        </template>
                        <span>Cost Per Reach</span>
                      </v-tooltip>
                    </p>
                    <p v-else class="text-14 mt-4">
                      CPI
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <svg v-bind="attrs"
                            v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                        </template>
                        <span>Cost Per Impressions</span>
                      </v-tooltip>
                    </p>
                    <h1 v-if="cprSum"><span class="text-16">IDR </span> {{ limitNumber(cpr) | nFormatter }}</h1>
                    <h1 v-else><span class="text-16">IDR </span> {{ limitNumber(cpi) | nFormatter }}</h1>
                  </div> -->
                  <div class="col-md-2 col-sm-4  col-6 border-right">
                    <p>
                      <v-btn
                        class="mx-2 ml-0"
                        fab
                        small
                        color="error"
                      >
                        <svg class="white--text text-22" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M11.975 22H12c3.859 0 7-3.14 7-7V9c0-3.841-3.127-6.974-6.981-7h-.06C8.119 2.022 5 5.157 5 9v6c0 3.86 3.129 7 6.975 7zM7 9a5.007 5.007 0 0 1 4.985-5C14.75 4.006 17 6.249 17 9v6c0 2.757-2.243 5-5 5h-.025C9.186 20 7 17.804 7 15V9z"/><path fill="currentColor" d="M11 6h2v6h-2z"/></svg>
                      </v-btn>
                    </p>
                    <p class="text-14 mt-4">Clicks</p>
                    <h1>{{ clicks | nFormatter}}</h1>
                    <p class="text-14 mt-4 mb-0">
                      <span class="font-weight-bold success--text">{{ limitNumber(share) | nFormatter }}</span>
                      Shares
                    </p>
                  </div>
                  <!-- <div v-else class="col-md-2 col-sm-4  col-6 border-right">
                    <div v-if="!impressionsSum">
                      <p>
                        <v-btn
                          class="mx-2 ml-0"
                          fab
                          small
                          color="success"
                          @click="changeSummary('impressions')"
                        >
                          <i class="far fa-thumbs-up white--text text-16"></i>
                        </v-btn>
                      </p>
                      <p class="text-14 mt-4">Likes</p>
                      <h1>{{ likes | nFormatter}}</h1>
                    </div>
                    <div v-else>
                      <p>
                        <v-btn
                          class="mx-2 ml-0"
                          fab
                          small
                          color="success"
                          @click="changeSummary('impressions')"
                        >
                          <i class="fas fa-history white--text text-16"></i>
                        </v-btn>
                      </p>
                      <p class="text-14 mt-4">Impressions</p>
                      <h1>{{ impressions | nFormatter}}</h1>
                    </div>
                  </div> -->
                  <!-- <div class="col-md-2 col-sm-4  col-6 border-right">
                    <div v-if="!commentSum">
                      <p>
                        <v-btn
                          class="mx-2 ml-0"
                          fab
                          small
                          color="info"
                          @click="changeSummary('comment')"
                        >
                        <i class="far fa-comments text-16 white--text"></i>
                        </v-btn>
                      </p>
                      <p class="text-14 mt-4">Comments</p>
                      <h1>{{ comments | nFormatter}}</h1>
                    </div>
                    <div v-else>
                      <p>
                        <v-btn
                          class="mx-2 ml-0"
                          fab
                          small
                          color="info"
                          @click="changeSummary('comment')"
                        >
                          <i class="far fa-eye white--text text-16"></i>
                        </v-btn>
                      </p>
                      <p class="text-14 mt-4">Views</p>
                      <h1>{{ views | nFormatter}}</h1>
                    </div>
                  </div> -->
                  <div class="col-md-2 col-sm-4 col-6">
                    <p>
                      <v-btn
                        class="mx-2 ml-0"
                        fab
                        small
                        color="accent"
                      >
                        <svg class="far fa-eye white--text text-20" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 0 0-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 0 1 5.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 0 1 9.288 0M15 7a3 3 0 1 1-6 0a3 3 0 0 1 6 0Zm6 3a2 2 0 1 1-4 0a2 2 0 0 1 4 0ZM7 10a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"/></svg>
                      </v-btn>
                    </p>
                    <p class="text-14 mt-4">Followers</p>
                    <h1>{{ followers | nFormatter}}</h1>
                    <p class="text-14 mt-4 mb-0">
                      <span class="font-weight-bold success--text">{{ limitNumber(account_engaged) | nFormatter }}</span>
                      AE
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <svg v-bind="attrs"
                            v-on="on" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                        </template>
                        <span>Account Engaged</span>
                      </v-tooltip>
                    </p>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </div>
        </v-row>
        <v-card color="" class="elevation-1 mt-5 pa-5 pt-2 round-16">
          <div class="font-weight-medium pl-0">
            <!-- DESKTOP -->
            <div class="headline black--text font-weight-bold d-flex mb-5 mt-3" v-if="!isMobile()">
              <div>
                <p class="mb-0">Influencers</p>
              </div>
              <div class="ml-auto">
                <v-btn outlined color="primary" class="text-capitalize mr-2" elevation="1" rounded @click.prevent="openInsight(itemData[0].url)">
                  <svg class="mr-2 text-16" xmlns="http://www.w3.org/2000/svg" width="0.88em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 448 512"><path fill="currentColor" d="M256 64c0-17.67 14.3-32 32-32h127.1c5.2 0 9.4.86 13.1 2.43c2.9 1.55 7.3 3.84 10.4 6.87c0 .05 0 .1.1.14c6.2 6.22 8.4 14.34 9.3 22.46V192c0 17.7-14.3 32-32 32s-32-14.3-32-32v-50.7L214.6 310.6c-12.5 12.5-32.7 12.5-45.2 0s-12.5-32.7 0-45.2L338.7 96H288c-17.7 0-32-14.33-32-32zM0 128c0-35.35 28.65-64 64-64h96c17.7 0 32 14.33 32 32c0 17.7-14.3 32-32 32H64v288h288v-96c0-17.7 14.3-32 32-32s32 14.3 32 32v96c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V128z"/></svg> Insight Files </v-btn>
                <v-btn color="primary" elevation="1" class="text-capitalize" rounded @click.prevent="downloadFile(itemData[0].name)"><i class="fas fa-download mr-2"></i> Export Excel</v-btn>
              </div>
            </div>
            <!-- MOBILE -->
            <!-- <div class="body-1 black--text font-weight-bold" v-else>
              Report {{ itemData[0].invoice}}
              <v-btn block color="primary" class="text-capitalize ml-auto mt-2" @click.prevent="downloadFile(itemData[0].invoice)"><i class="fas fa-download mr-2"></i> Download Excel</v-btn>
            </div> -->
          </div>
          <v-data-table
              :headers="headers"
              :items="itemData"
              item-key="id"
              class="elevation-0 text-capitalize"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
              :loading="loadingTable"
              loading-text="Loading... Memuat Data"
          >
            <template v-slot:[`item.iron`]="{ item }">
                <v-avatar class="ma-2">
                    <v-img :src="item.iron" :alt="item.iron" height="100px"></v-img>
                </v-avatar>
            </template>
            <template v-slot:[`item.categories`]="{ item }">
              <div>
                <v-chip
                  class="ma-1 caption"
                  color="secondary"
                  v-for="category in item.categories" :key="category.id"
                >
                  {{ category.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.follower`]="{ item }">
              <div v-if="item.follower > 0">
                {{ item.follower | numberFormat }}
              </div>
              <div v-else>
                {{ item.follower }}
              </div>
            </template>
            <template v-slot:[`item.region`]="{ item }">
              <div v-if="item.region">
                {{ item.region }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.reach`]="{ item }">
              <div v-if="item.reach > 0">
                {{ item.reach | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.impressions`]="{ item }">
              <div v-if="item.impressions > 0">
                {{ item.impressions | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.likes`]="{ item }">
              <div v-if="item.likes > 0">
                {{ item.likes | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.comments`]="{ item }">
              <div v-if="item.comments > 0">
                {{ item.comments | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.views`]="{ item }">
              <div v-if="item.views > 0">
                {{ item.views | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.shares`]="{ item }">
              <div v-if="item.shares > 0">
                {{ item.shares | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.clicks`]="{ item }">
              <div v-if="item.clicks > 0">
                {{ item.clicks | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.saved`]="{ item }">
              <div v-if="item.saved > 0">
                {{ item.saved | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.reply`]="{ item }">
              <div v-if="item.reply > 0">
                {{ item.reply | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.account_engaged`]="{ item }">
              <div v-if="item.account_engaged > 0">
                {{ item.account_engaged | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.play`]="{ item }">
              <div v-if="item.play > 0">
                {{ item.play | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.taps`]="{ item }">
              <div v-if="item.taps > 0">
                {{ item.taps | numberFormat }}
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.avd`]="{ item }">
              <div v-if="item.avd > 0">
                {{ item.avd | numberFormat }} Sec
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.wfv`]="{ item }">
              <div v-if="item.wfv > 0">
                {{ item.wfv | numberFormat }}%
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template v-slot:[`item.link`]="{ item }">
              <v-btn v-if="item.link" rounded small color="primary" class="text-capitalize" @click="openLink(item.link)">Buka</v-btn>
              <div v-else>-</div>
            </template>
            <template v-slot:[`item.upload_date`]="{ item }">
              <div>{{ item.upload_date | dateFormat3}}</div>
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              <div v-if="item.gender === 'L'">
                Laki-laki
              </div>
              <div v-else>
                Perempuan
              </div>
            </template>
          </v-data-table>
          <div class="text-center mt-2 pt-0">
            <v-pagination
                v-model="page"
                :length="pageCount"
            ></v-pagination>
          </div>
        </v-card>
      </div>
      <!-- MOBILE -->
      <div v-else>
        <div class="mt-0">
          <div class="row">
            <div class="col-md-2 col-sm-4 col-6">
              <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
                <p class="mb-2">
                  <v-btn
                    class=""
                    fab
                    small
                    color="orange"
                  >
                    <i class="far fa-user white--text text-16"></i>
                  </v-btn>
                </p>
                <p class="text-12 mb-0">People Reached</p>
                <h3>{{ reach | nFormatter}}</h3>
                <p class="text-12 mb-0">
                  <span class="font-weight-bold success--text">IDR {{ limitNumber(cpr) | nFormatter }}</span>
                  CPR
                  <svg @click="showCpr = !showCpr"
                  xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                  <v-btn
                    v-if="showCpr"
                    class="text-capitalize white--text rounded-lg text-10"
                    small
                    color="black"
                  ><span>Cost Per Reach</span>
                  </v-btn>
                </p>
              </v-card>
            </div>
            <div class="col-md-2 col-sm-4 col-6">
              <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
                <v-btn
                  class="mb-2"
                  fab
                  small
                  color="success"
                >
                  <i class="fas fa-history white--text text-16"></i>
                </v-btn>
                <p class="text-12 mb-0">Impressions</p>
                <h3>{{ impressions | nFormatter}}</h3>
                <p class="text-12 mb-0">
                  <span class="font-weight-bold success--text">IDR {{ limitNumber(cpi) | nFormatter }}</span>
                  CPI
                  <svg @click="showCpi = !showCpi"
                  xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                  <v-btn
                    v-if="showCpi"
                    class="text-capitalize white--text rounded-lg text-10"
                    small
                    color="black"
                  ><span>Cost Per Impressions</span>
                  </v-btn>
                </p>
              </v-card>
            </div>
            <div class="col-md-2 col-sm-4 col-6 pt-0">
              <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
                <v-btn
                  class="mb-2"
                  fab
                  small
                  color="info"
                >
                  <i class="far fa-eye white--text text-16"></i>
                </v-btn>
                <p class="text-12 mb-0">Views</p>
                <h3>{{ views | nFormatter}}</h3>
                <p class="text-12 mb-0">
                  <span class="font-weight-bold success--text">IDR {{ limitNumber(cpv) | nFormatter }}</span>
                  CPV
                  <svg @click="showCpv = !showCpv"
                  xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                  <v-btn
                    v-if="showCpv"
                    class="text-capitalize white--text rounded-lg text-10"
                    small
                    color="black"
                  ><span>Cost Per Views</span>
                  </v-btn>
                </p>
              </v-card>
            </div>
            <div class="col-md-2 col-sm-4 col-6 pt-0">
              <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
                <v-btn
                  class="mb-2"
                  fab
                  small
                  color="secondary"
                >
                <i class="far fa-thumbs-up white--text text-16"></i>
                </v-btn>
                <p class="text-12 mb-0">Likes</p>
                <h3>{{ likes | nFormatter}}</h3>
                <p class="text-12 mb-0">
                  <span class="font-weight-bold success--text">{{ limitNumber(comments) | nFormatter }}</span>
                  Comments
                </p>
              </v-card>
            </div>
            <div class="col-md-2 col-sm-4 col-6 pt-0">
              <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
                <v-btn
                  class="mb-2"
                  fab
                  small
                  color="error"
                >
                  <svg class="white--text text-22" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M11.975 22H12c3.859 0 7-3.14 7-7V9c0-3.841-3.127-6.974-6.981-7h-.06C8.119 2.022 5 5.157 5 9v6c0 3.86 3.129 7 6.975 7zM7 9a5.007 5.007 0 0 1 4.985-5C14.75 4.006 17 6.249 17 9v6c0 2.757-2.243 5-5 5h-.025C9.186 20 7 17.804 7 15V9z"/><path fill="currentColor" d="M11 6h2v6h-2z"/></svg>
                </v-btn>
                <p class="text-12 mb-0">Clicks</p>
                <h3>{{ clicks | nFormatter}}</h3>
                <p class="text-12 mb-0">
                  <span class="font-weight-bold success--text">{{ limitNumber(share) | nFormatter }}</span>
                  Shares
                </p>
              </v-card>
            </div>
            <div class="col-md-2 col-sm-4 col-6 pt-0">
              <v-card class="overflow-hidden pa-3 round-16" elevation="3" outlined>
                <v-btn
                  class="mb-2"
                  fab
                  small
                  color="accent"
                >
                  <svg class="far fa-eye white--text text-20" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 0 0-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 0 1 5.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 0 1 9.288 0M15 7a3 3 0 1 1-6 0a3 3 0 0 1 6 0Zm6 3a2 2 0 1 1-4 0a2 2 0 0 1 4 0ZM7 10a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"/></svg>
                </v-btn>
                <div>
                  <p class="text-12 mb-0">Followers</p>
                  <h3>{{ followers | nFormatter}}</h3>
                </div>
                <p class="text-12 mb-0">
                  <span class="font-weight-bold success--text">{{ limitNumber(account_engaged) | nFormatter }}</span>
                  AE
                  <svg @click="showAe = !showAe"
                  xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64zm67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344zM590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.992 12.992 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"/></svg>
                </p>
                <v-btn
                  v-if="showAe"
                  class="text-capitalize white--text rounded-lg text-10"
                  small
                  color="black"
                ><span>Account Engaged</span>
                </v-btn>
              </v-card>
            </div>
          </div>
        </div>
        <v-card color="" class="elevation-0 mt-5 round-16">
          <v-card-text class="pb-1 pl-0 pr-0">
            <div>
              <h3 class="mb-2 black--text font-weight-bold">Influencers</h3>
            </div>
            <div class="row mb-0">
              <div class="col-6">
                <v-btn outlined rounded color="primary" class="text-capitalize full-width" @click.prevent="openInsight(itemData[0].url)">
                  <svg class="text-16 mr-2" xmlns="http://www.w3.org/2000/svg" width="1.13em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 576 512"><path fill="currentColor" d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v48H54a6 6 0 0 0-6 6v244a6 6 0 0 0 6 6h372a6 6 0 0 0 6-6v-10h48zm42-336H150a6 6 0 0 0-6 6v244a6 6 0 0 0 6 6h372a6 6 0 0 0 6-6V86a6 6 0 0 0-6-6zm6-48c26.51 0 48 21.49 48 48v256c0 26.51-21.49 48-48 48H144c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h384zM264 144c0 22.091-17.909 40-40 40s-40-17.909-40-40s17.909-40 40-40s40 17.909 40 40zm-72 96l39.515-39.515c4.686-4.686 12.284-4.686 16.971 0L288 240l103.515-103.515c4.686-4.686 12.284-4.686 16.971 0L480 208v80H192v-48z"/></svg> Insight Files </v-btn>
              </div>
              <div class="col-6">
                <v-btn rounded color="primary" class="text-capitalize full-width" @click.prevent="downloadFile(itemData[0].invoice)"><i class="fas fa-download mr-2"></i> Export Excel</v-btn>
              </div>
            </div>
          </v-card-text>
          <v-data-iterator
            :headers="headers"
            :items="itemData"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            item-key="id"
            class="elevation-0"
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="3"
                >
                  <v-card class="round-16" outlined elevation="3">
                    <!-- <v-card-title class="text-12 font-weight-bold pl-4 pt-2 pb-2">
                      <p class="mb-0 mr-1">{{ item.num }}.</p><p class="black--text mb-0">{{ item.username }}</p>
                    </v-card-title>
                    <v-divider></v-divider> -->
                    <v-list dense class="text-12 pb-3">
                      <v-list-item style="min-height:25px !important; height:25px">
                        <v-list-item-content class="text-14 font-weight-bold pb-0">{{ item.num }}. {{ item.username}}</v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Followers</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">{{ item.follower | numberFormat }}</v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Content</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">{{ item.content }}</v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Upload</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">{{ item.upload_date | dateFormat2 }}</v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="regionHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Region</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.region != null" class="text-capitalize">
                              {{ item.region }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="reachHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Reach</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.reach > 0">
                              {{ item.reach | nFormatter }}
                            </div>
                            <div v-else >
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="impressionsHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Impressions</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.impressions > 0">
                              {{ item.impressions | nFormatter }}
                            </div>
                            <div v-else >
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="viewsHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Views</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.views > 0">
                              {{ item.views | nFormatter }}
                            </div>
                            <div v-else >
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="clicksHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Clicks</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.clicks > 0">
                              {{ item.clicks | nFormatter }}
                            </div>
                            <div v-else >
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="likesHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Likes</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.likes > 0">
                              {{ item.likes | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="commentsHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Comments</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.comments > 0">
                              {{ item.comments | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="sharesHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Shares</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.shares > 0">
                              {{ item.shares | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="savedHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Saved</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.saved > 0">
                              {{ item.saved | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="replyHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Reply</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.reply > 0">
                              {{ item.reply | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="accountEngagedHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Account Engaged</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.account_engaged > 0">
                              {{ item.account_engaged | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="playHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Play</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.play > 0">
                              {{ item.play | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="tapsHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Taps</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.taps > 0">
                              {{ item.taps | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="avdHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">AVD</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.avd > 0">
                              {{ item.avd | nFormatter }} Sec
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="wfvHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">WFV</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.wfv > 0">
                              {{ item.wfv | nFormatter }}%
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="retweetHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Retweet</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.retweet > 0">
                              {{ item.retweet | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="expandsHead > 0" style="min-height:25px !important; height:25px">
                        <v-list-item-content class=" pb-0">Expands</v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text class="pt-1 font-weight-bold">
                            <div v-if="item.expands > 0">
                              {{ item.expands | nFormatter }}
                            </div>
                            <div v-else>
                              -
                            </div>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  setup() {
    return{
      totalPrice:0,
      itemData:[],
      isLoading:true,
      loadingTable:true,
      total:0,
      serviceFee:0,
      order:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      itemsPerPageMobile: 5,
      index:0,
      level:[],
      impressions:0,
      reach:0,
      likes:0,
      views:0,
      comments:0,
      share:0,
      saved:0,
      reply:0,
      account_engaged:0,
      clicks:0,
      followers:0,
      cpr:0,
      cpi:0,
      cpv:0,
      show:false,
      headers:[
        {text: 'No', value: 'num', sortable: false, width: "1%"},
        {text: 'User', value: 'username', sortable: false},
        {text: 'Follower', align: 'left', sortable: false, value: 'follower'},
        {text: 'Content', align: 'left', sortable: false, value: 'content'},
        {text: 'Upload', value: 'upload_date', sortable: false, width: '120px'},
      ],
      impressionsHead:0,
      reachHead:0,
      likesHead:0,
      viewsHead:0,
      commentsHead:0,
      sharesHead:0,
      savedHead:0,
      replyHead:0,
      accountEngagedHead:0,
      clicksHead:0,
      regionHead:0,
      playHead:0,
      tapsHead:0,
      avdHead:0,
      wfvHead:0,
      linkHead:0,
      retweetHead:0,
      expandsHead:0,
      cprSum: true,
      impressionsSum: true,
      commentSum: true,
      showCpr: false,
      showCpi: false,
      showCpv: false,
      showAe: false
    }
  },
  mounted(){
    let id_ = this.$route.query.id
    this.id = this.decryptData(id_)

    if(parseInt(this.id) > 0){
      this.loadCampaign()
    } else {
      this.$router.push({name:'campaigns'});
    }
  },
  computed: {
    // headers() {
    //   return [
    //     {text: 'No', value: 'num', sortable: false, width: "1%"},
    //     // {text: 'Paket', value: 'packages.name', sortable: false, width: "1%"},
    //     // {text: 'Gambar', value: 'iron', sortable: false, width: "0.5%"},
    //     {text: 'User', value: 'username', sortable: false},
    //     {text: 'Follower', align: 'left', sortable: false, value: 'follower'},
    //     {text: 'Content', align: 'left', sortable: false, value: 'content'},
    //     {text: 'Upload', value: 'upload_date', sortable: false},
    //     // {text: 'Konten', value: 'packages.content', sortable: false, width: "30%"},
    //     {text: 'Region', align: 'center', value: 'region', sortable: false},
    //     {text: 'Reach', align: 'center', value: 'reach', sortable: false},
    //     {text: 'Clicks', align: 'center', value: 'clicks', sortable: false},
    //     {text: 'Impressions',align: 'center',  value: 'impressions', sortable: false},
    //     {text: 'Views',align: 'center',  value: 'views', sortable: false},
    //     {text: 'Likes', align: 'center', value: 'likes', sortable: false},
    //     {text: 'Comments', align: 'center', value: 'comments', sortable: false},
    //     {text: 'Shares', align: 'center', value: 'shares', sortable: false},
    //     {text: 'Saved', align: 'center', value: 'saved', sortable: false},
    //     {text: 'Play', value: 'play', sortable: false},
    //     {text: 'Taps', value: 'taps', sortable: false},
    //   ]
    // },
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadCampaign(){
      this.isLoading = true;
      this.loadingTable = true
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'campaign/getCampaignDetail/'+this.id, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          // console.log(response)
          if(response.status === 200){
            this.itemData = response.data.data
            let uniqueUsernames = new Set();
            for(let a = 0; a < this.itemData.length; a++){
              if(this.itemData[a].reach){
                this.reachHead += 1;
              }
              if(this.itemData[a].impressions){
                this.impressionsHead += 1;
              }
              if(this.itemData[a].region){
                this.regionHead += 1;
              }
              if(this.itemData[a].likes){
                this.likesHead += 1;
              }
              if(this.itemData[a].views){
                this.viewsHead += 1;
              }
              if(this.itemData[a].comments){
                this.commentsHead += 1;
              }
              if(this.itemData[a].shares){
                this.sharesHead += 1;
              }
              if(this.itemData[a].saved){
                this.savedHead += 1;
              }
              if(this.itemData[a].reply){
                this.replyHead += 1;
              }
              if(this.itemData[a].account_engaged){
                this.accountEngagedHead += 1;
              }
              if(this.itemData[a].clicks){
                this.clicksHead += 1;
              }
              if(this.itemData[a].play){
                this.playHead += 1;
              }
              if(this.itemData[a].taps){
                this.tapsHead += 1;
              }
              if(this.itemData[a].avd){
                this.avdHead += 1;
              }
              if(this.itemData[a].wfv){
                this.wfvHead += 1;
              }
              if(this.itemData[a].link){
                this.linkHead += 1;
              }
              if(this.itemData[a].retweet){
                this.retweetHead += 1;
              }
              if(this.itemData[a].expands){
                this.expandsHead += 1;
              }
              this.itemData[a].date_added = new Date(this.itemData[a].date_added)
              // this.itemData[a].upload_date = new Date(this.itemData[a].upload_date.replace(/-/g, "/"))
              this.itemData[a].campaign_date = new Date(this.itemData[a].campaign_date)
              // this.itemData[a].upload_date = this.itemData[a].upload_date.slice(0,10)
              this.itemData[a].num = a+1
              this.impressions += this.itemData[a].impressions ? parseInt(this.itemData[a].impressions) : 0
              this.reach += this.itemData[a].reach ? parseInt(this.itemData[a].reach) : 0
              this.views += this.itemData[a].views ? parseInt(this.itemData[a].views) : 0
              this.likes += this.itemData[a].likes ? parseInt(this.itemData[a].likes) : 0
              this.comments += this.itemData[a].comments ? parseInt(this.itemData[a].comments) : 0
              this.clicks += this.itemData[a].clicks ? parseInt(this.itemData[a].clicks) : 0

              if (!uniqueUsernames.has(this.itemData[a].username)) {
                this.followers += this.itemData[a].follower ? parseInt(this.itemData[a].follower) : 0
                uniqueUsernames.add(this.itemData[a].username);
              }
              this.share += this.itemData[a].shares ? parseInt(this.itemData[a].shares) : 0
              this.saved += this.itemData[a].saved ? parseInt(this.itemData[a].saved) : 0
              this.reply += this.itemData[a].reply ? parseInt(this.itemData[a].reply) : 0
              this.account_engaged += this.itemData[a].account_engaged ? parseInt(this.itemData[a].account_engaged) : 0
            }
              if(this.regionHead > 0){
                this.headers.push({text: 'Region', align: 'center', value: 'region', sortable: false, width: '150px'})
              }
              if(this.reachHead > 0){
                this.headers.push({text: 'Reach', align: 'center', value: 'reach', sortable: false})
              }
              if(this.impressionsHead > 0){
                this.headers.push({text:'Impressions', value:'impressions', sortable:false})
              }
              if(this.viewsHead > 0){
                this.headers.push({text:'views', value:'views', sortable:false})
              }
              if(this.clicksHead > 0){
                this.headers.push({text:'clicks', value:'clicks', sortable:false})
              }
              if(this.likesHead > 0){
                this.headers.push({text:'likes', value:'likes', sortable:false})
              }
              if(this.commentsHead > 0){
                this.headers.push({text:'comments', value:'comments', sortable:false})
              }
              if(this.sharesHead > 0){
                this.headers.push({text:'shares', value:'shares', sortable:false})
              }
              if(this.savedHead > 0){
                this.headers.push({text:'saved', value:'saved', sortable:false})
              }
              if(this.replyHead > 0){
                this.headers.push({text:'reply', value:'reply', sortable:false})
              }
              if(this.accountEngagedHead > 0){
                this.headers.push({text:'account_engaged', value:'account_engaged', sortable:false})
              }
              if(this.playHead > 0){
                this.headers.push({text:'play', value:'play', sortable:false})
              }
              if(this.tapsHead > 0){
                this.headers.push({text:'taps', value:'taps', sortable:false})
              }
              if(this.avdHead > 0){
                this.headers.push({text:'AVD', value:'avd', sortable:false, width: '80px'})
              }
              if(this.wfvHead > 0){
                this.headers.push({text:'WFV', value:'wfv', sortable:false})
              }
              if(this.linkHead > 0){
                this.headers.push({text:'URL', value:'link', sortable:false, align:'center'})
              }
              if(this.retweetHead > 0){
                this.headers.push({text:'Retweet', value:'retweet', sortable:false, align:'center'})
              }
              if(this.expandsHead > 0){
                this.headers.push({text:'Expands', value:'expands', sortable:false, align:'center'})
              }
            this.itemData[0].date = this.checkCamp(new Date(this.itemData[0].start_date), new Date(this.itemData[0].end_date))
            if(this.isMobile()){
              this.itemsPerPage = this.itemData.length
            }
            this.cpr = this.reach ? parseInt(this.itemData[0].budget) / this.reach : 0
            this.cpi = this.impressions ? parseInt(this.itemData[0].budget) / this.impressions : 0
            this.cpv = this.views ? parseInt(this.itemData[0].budget) / this.views : 0
            // this.level = [ ...new Set(this.level) ]
            // console.log(this.itemData)
            setTimeout(() => {
              this.isLoading = false
              this.loadingTable = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    checkCamp(start, end){
      let startYear = start.toString().slice(11, 16)
      let startMonth = start.toString().slice(4, 7)
      let startDay = start.toString().slice(8, 10)
      let endYear = end.toString().slice(11, 16)
      let endMonth = end.toString().slice(4, 7)
      let endDay = end.toString().slice(8, 10)
      let date;
      if(startYear === endYear && startMonth === endMonth && startDay === endDay){
        date = startDay+' '+startMonth+' '+startYear
      } else  if(startYear === endYear && startMonth === endMonth && startDay != endDay){
        date = startDay+' - '+endDay+' '+startMonth+' '+startYear
      } else  if(startYear === endYear && startMonth != endMonth){
        date = startDay+' '+startMonth+' - '+endDay+' '+endMonth+' '+startYear
      } else if(startYear != endYear){
        date = startDay+' '+startMonth+' '+startYear+' - '+endDay+' '+startMonth+' '+endYear
      }
      return date
    },
    downloadFile(campaign) {
      let token = localStorage.getItem('token')
      axios({
        url: process.env.VUE_APP_API_ENDPOINT + 'campaign/exportCampaign_/'+this.id,
        headers: {
          'Authorization': 'Bearer '+ token
        },
        method: 'GET',
        responseType: 'blob',
      }).then((res) => {
        const blob = new Blob([res.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'Report VDC '+campaign+'.xlsx';
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    changeSummary(summary){
      if(summary === 'cpr'){
        this.cprSum = !this.cprSum;
      } else if(summary === 'impressions'){
        this.impressionsSum = !this.impressionsSum;
      } else if(summary === 'comment'){
        this.commentSum = !this.commentSum;
      }
    },
    openInsight(url){
      window.open(url,'_blank')
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    limitNumber(value){
      return Number((value).toFixed(1));
    },
    openLink(link){
      window.open(link, '_blank');
    },
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    },
    dateFormat(value){
      return value.toString().slice(0, 24)
    },
    dateFormat2(value){
      return value.toString().slice(0, 16)
    },
    dateFormat3(value){
      return value.toString().slice(0, 10)
    },
    levelFormat(value){
      if(value === 'A'){
        return 'Nano'
      } else {
        return 'Micro'
      }
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  },
})
</script>
