<template>
  <v-row :class="{'ma-4 mt-0 pt-0 mb-10 pb-5': isMobile()}">
    <v-col cols="12" :class="{'pl-0 pr-0': isMobile()}">
      <campaigns-detail></campaigns-detail>
    </v-col>
  </v-row>
</template>

<script>
import CampaignsDetail from './components/CampaignsDetail.vue'

export default {
  components: {
    CampaignsDetail,
  },
  setup() {
    return {
      isMobileWidth:0,
    }
  },
  mounted(){
    this.isMobile_()
    window.addEventListener('resize', () => {
      this.isMobile_()
    })
    
    if(this.isMobileWidth === 1){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
    }
  },  
  methods:{
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    isMobile_() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  }
}
</script>
